<template>
  <div :style="{height: '100%'}">
    <div v-if="isStandalone" class="reload-button-container">
      <v-btn
        icon
        @click="reloadPage"
        color="accent"
        class="white"
      >
        <v-icon>mdi-refresh</v-icon>
      </v-btn>
    </div>

    <div v-if="!loading && !block" class="error-display d-flex justify-center align-center text-h4">{{errorMessage || $t('errorMessages.unaccessibleView')}}</div>
    <div v-else-if="block" class="block-page-root" data-testid="standaloneBlockPage">
      <LoadingOverlay :show="loading" />
      <v-img
        v-if="headerImageUrl"
        :src="headerImageUrl"
        class="headerImage"
      />
      <div class="mx-4 my-4 mt-12">
        <v-row            
          v-for="child in children"
          :key="child.id"
          justify="center"
          class="mx-0"
        >
          <v-col
            v-bind="columnSize(child)"
          >
            <component
              :ref="`block_${child.id}`"
              :is="component(child)"
              :blockUri="child.uri"
              :editorMode="false"
              style="overflow-wrap: break-word;"
              :hideChildPages="true"
            />
          </v-col>
        </v-row>
      </div>
    </div>
    <LoadingOverlay :show="loading" />
    <LoginDialog @credentialsProvided="credentialsProvided" v-model="loginDialog" :resource="loginResource" />
  </div>
</template>

<script>
import CellInputBlock from '@/components/block/CellInputBlock.vue'
import LoadingOverlay from '@/components/LoadingOverlay.vue'
import LoginDialog from '@/components/LoginDialog.vue'
import linkAuthorizationNeededBus from '@/utils/linkAuthorizationNeededBus'

export default {
  props: {
    blockUri: {
      type: String,
      default: null
    }
  },
  provide() {
    return {
      canAddChild: () => false
    }
  },
  data() {
    return {
      loading: false,
      loginDialog: false,
      uri: undefined,
      errorMessage: undefined,
      canLogout: false,
      isStandalone: false
    }
  },
  computed: {
    headerImageUrl() {
      return this.block?.headerImageUrl
    },
    title() {
      return this.block?.name || this.$t('blocks.emptyPageTitle')
    },
    block() {
      return this.$store.getters.blockWithUri(this.resolvedBlockUri)
    },
    logo() {
      return `${this.$settings.app}/logo.svg`
    },
    embedded() {
      const urlParams = new URLSearchParams(window.location.search)
      const embedded = urlParams.get('embedded')
      return embedded == 'true'
    },
    loginResource() {
      return this.uri?.uri
    },
    resolvedBlockUri() {
      return this.uri?.uri
    },
    children() {
      return this.block?.children.filter(child => child.type != 'page')
    }
  },
  watch: {
    blockUri: {
      immediate: true,
      handler(newVal) {
        linkAuthorizationNeededBus.onLinkAuthorizationNeeded(this.onLinkAuthorizationNeeded)
        if(newVal) {
          this.uri = { uri: newVal }
        } else {
          // here we get the uri from the search params as vue router ignores queries that are before the /#block/
          const urlParams = new URLSearchParams(window.location.search)
          this.uri = { uri: urlParams.get('uri') }
        }
        this.loadBlock()
      }
    },
    title: {
      immediate: true,
      handler(newVal) {
        document.title = newVal
      }
    }
  },
  beforeDestroy() {
    linkAuthorizationNeededBus.linkAuthorizationNeededOff(this.onLinkAuthorizationNeeded)
  },
  mounted() {
    // Check if running as PWA
    this.isStandalone = window.matchMedia('(display-mode: standalone)').matches ||
      window.navigator.standalone ||
      document.referrer.includes('android-app://')
    // Add meta tags for PWA
    this.addPWAMetaTags()
  },
  methods: {
    onLinkAuthorizationNeeded() {
      this.loginDialog = true
    },
    async credentialsProvided() {
      this.canLogout = true
      await this.loadBlock()
    },
    async loadBlock() {
      if (!this.uri?.uri) return
      
      this.loading = true
      try {
        const block = await this.$store.dispatch('AGReadBlock', { 
          blockUri: this.uri.uri, 
          customErrorHandling: [400, 404]
        })
        // If this is a child block, we need to load its parent for navigation
        if (block.parent) {
          await this.$store.dispatch('AGReadBlock', { 
            blockUri: block.parent.uri, 
            customErrorHandling: [400, 404]
          })
        }
        this.uri.uri = block._links.self.href
      } catch (error) { 
        if (error.response?.status === 404 || error.response?.status === 400) {
          this.errorMessage = this.$t('errorMessages.unaccessibleView')
        }
      } finally {
        this.loading = false
      }
      this.$store.dispatch('loadUser')

    },
    logout() {
      window.location.reload()
    },
    columnSize(child) {
      const isFullWidth = child.width === 'fullwidth'
      return {
        cols: 12,
        sm: isFullWidth ? 10 : 10,
        md: isFullWidth ? 10 : 9,
        lg: isFullWidth ? 11 : 7
      }
    },
    component(block) {
      return block.blockType.component
    },
    reloadPage() {
      window.location.reload()
    },
    addPWAMetaTags() {
      const metaTags = [
        { name: 'apple-mobile-web-app-capable', content: 'yes' },
        { name: 'apple-mobile-web-app-status-bar-style', content: 'default' },
        { name: 'mobile-web-app-capable', content: 'yes' },
      ]
      
      metaTags.forEach(tag => {
        const meta = document.createElement('meta')
        meta.name = tag.name
        meta.content = tag.content
        document.head.appendChild(meta)
      })
    }
  },
  components: {
    CellInputBlock,
    LoadingOverlay,
    LoginDialog
  }
}
</script>

<style>
.error-display {
  width: 100%;
  height: 100%;
  text-align: center;
}

.block-page-root {
  width: 100%;
  background: white;
  position: inherit;
  overflow: auto;
}

.headerImage {
  object-fit: cover;
  max-height: 240px;
  min-height: 220px;
}

/* Add responsive heights for smaller screens */
@media (max-width: 600px) {
  .headerImage {
    max-height: 120px;
    min-height: 100px;
  }
}

.reload-button-container {
  position: fixed;
  top: 8px;
  right: 8px;
  z-index: 1000;
}
</style> 
