import { render, staticRenderFns } from "./FormShareMenu.vue?vue&type=template&id=652e6be5&scoped=true&"
import script from "./FormShareMenu.vue?vue&type=script&lang=js&"
export * from "./FormShareMenu.vue?vue&type=script&lang=js&"
import style0 from "./FormShareMenu.vue?vue&type=style&index=0&id=652e6be5&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "652e6be5",
  null
  
)

export default component.exports