<template>
  <div>
    <!-- Single toggle button with dynamic icon -->
    <v-btn
      v-if="hasChildPages && !isDrawerOpen"
      icon
      class="mt-2 navigation-drawer-open-button white"
      :class="{ 'ml-2': !isDrawerOpen, 'mr-2': isDrawerOpen }"
      @click="isDrawerOpen = !isDrawerOpen"
      color="accent"
    >
      <v-icon>mdi-menu</v-icon>
    </v-btn>

    <v-navigation-drawer
      v-if="hasChildPages"
      v-model="isDrawerOpen"
      app
      clipped
      class="pt-4"
      width="300"
      color="grey lighten-4"
      floating
    >

    <v-btn
      v-if="hasChildPages && isDrawerOpen && !$vuetify.breakpoint.mobile"
      icon
      class="mt-2 navigation-drawer-toggle-close-button"
      @click="isDrawerOpen = !isDrawerOpen"
    >
      <v-icon>mdi-menu</v-icon>
    </v-btn>

      <a class="ml-6" href="https://www.apptivegrid.de?utm_source=app-apptivegrid&utm_medium=shared-link&utm_campaign=apptive-shared-view" target="_blank" >
        <img class="" width="130px" :src="logo" />
      </a>

      <v-list dense nav class="pt-4">
        <template v-if="rootBlock">
          <!-- Home navigation item -->
          <v-list-item
            :to="{ name: 'StandaloneBlockPageSearchParams'}"
            link
            exact
            class="pl-3"
          >
            <v-list-item-icon  class="mr-2">
              <v-icon >mdi-home-variant-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="text-subtitle-2">
                {{ rootBlock.name || $t('blocks.emptyPageTitle') }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            v-for="page in flattenedPages"
            :key="page.uri"
            :to="{ name: 'StandaloneBlockPage', params: { blockUri: page.uri }}"
            link
            :class="'pl-' + (page.level) + ' ' + 'ml-' + (page.level + (page.level * 1))"
          >
            <v-list-item-icon class="mr-1">
              <v-icon v-if="page.level > 1">mdi-circle-small</v-icon>
              <v-icon v-else>mdi-arrow-right-thin</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title class="text-subtitle-2">
                {{ page.name || $t('blocks.emptyPageTitle') }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
      <v-spacer></v-spacer>
      <template v-slot:append>
        <v-sheet v-if="user" rounded="lg" class="ma-3" elevation="4">
          <UserProfileInfo :showSettings="false" />
        </v-sheet>
      </template>
      
    </v-navigation-drawer>
  </div>
</template>

<script>
import UserProfileInfo from '@/components/user/UserProfileInfo.vue'

export default {
  props: {
    blockUri: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      isDrawerOpen: true,
      rootBlockUri: null
    }
  },
  computed: {
    user() {
      return this.$store.state.user?.user
    },
    pageBlocks() {
      return this.rootBlock?.children?.filter(child => child.type === 'page') || []
    },
    rootBlock() {
      return this.$store.getters.blockWithUri(this.rootBlockUri)
    },
    flattenedPages() {
      const result = []
      
      const addPagesRecursively = (pages, level) => {
        pages.forEach(page => {
          result.push(Object.assign(page, { level }))
          
          const childPages = page.children?.filter(child => child.type === 'page') || []
          if (childPages.length > 0 && level < 3) {
            addPagesRecursively(childPages, level + 1)
          }
        })
      }
      
      addPagesRecursively(this.pageBlocks, 1)
      return result
    },
    hasChildPages() {
      return this.pageBlocks?.length > 0
    },
    logo() {
      return `${this.$settings.app}/logo.svg`
    },
  },
  mounted() {
    this.isDrawerOpen = !this.$vuetify.breakpoint.mobile

    const urlParams = new URLSearchParams(window.location.search)
    this.rootBlockUri = urlParams.get('uri')
    this.loadBlock()
  },
  methods: {
    isSelected(block) {
      return block.uri === this.blockUri || block.uri === this.rootBlockUri
    },
    async loadBlock() {
      if (this.rootBlock) return
      
      try {
        const block = await this.$store.dispatch('AGReadBlock', { 
          blockUri: this.rootBlockUri, 
          customErrorHandling: [400, 404]
        })

        this.rootBlockUri = block._links.self.href
        this.uri.uri = block._links.self.href
      } catch (error) { 
        if (error.response?.status === 404 || error.response?.status === 400) {
          this.errorMessage = this.$t('errorMessages.unaccessibleView')
        }
      }
    },
  },
  components: {
    UserProfileInfo
  }
}
</script>

<style scoped>
.navigation-drawer-open-button { 
  position: absolute;
  top: 2px;
  left: 8px;
  z-index: 1000;
}

.navigation-drawer-toggle-close-button { 
  position: absolute;
  top: 2px;
  right: 16px;
  z-index: 1000;
}

</style> 