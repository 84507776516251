<template>
  <div class="d-flex align-center pa-3">
    <AvatarEditor :size="avatarSize" />
    <div class="d-flex flex-column justify-center profile-info">
      <div class="bold truncate" data-testid="userFullName">{{ fullName }}</div>
      <div class="truncate" data-testid="userEmail">{{ email }}</div>
      <router-link v-if="showSettings" class="settings-link" :to="{ name: 'Profile' }">{{
        $t('settings.accountSettingsButton')
      }}</router-link>
    </div>
  </div>
</template>

<script>
import AvatarEditor from '@/components/user/AvatarEditor.vue'

export default {
  name: 'UserProfileInfo',
  props: {
    showSettings: {
      type: Boolean,
      default: true
    },
    avatarSize: {
      type: Number,
      default: 36
    }
  },
  computed: {
    email() {
      return this.$store.state.user.user?.email
    },
    fullName() {
      return `${this.$store.state.user.user?.firstName} ${this.$store.state.user.user?.lastName}`.trim()
    }
  },
  components: {
    AvatarEditor
  }
}
</script>

<style lang="css">
.bold,
.settings-link {
  font-weight: bold;
}

.settings-link {
  text-decoration: unset;
}

.profile-info {
  max-width: 100%;
  min-width: 0;
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}
</style> 