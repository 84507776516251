<template>
  <BaseBlock :blockUri="blockUri" v-on="$listeners" :editorMode="editorMode">
    <template v-slot:menu>
      <MenuItem
        icon="mdi-cog-outline"
        :text="$t('blocks.externalHookButtonBlock.menuButton')"
        @click="openMenu"
      />
    </template>

    <div
      v-if="!resource"
      class="resource-empty d-flex flex-row gap-3 pa-3"
      @click="openMenu"
    >
      <v-icon color="#75746F">mdi-button-cursor</v-icon>
      <div>{{ $t('blockTypes.externalHookButtonBlock.subtitle') }}</div>
    </div>
    <div v-else>
      <v-btn color="primary" rounded @click="buttonClicked">
      {{ resource.name }}
    </v-btn>
    </div>
 
    <v-dialog
      v-model="showDialog"
      scrollable
      transition="dialog-bottom-transition"
    >
      <v-card class="dialog-card" v-if="resource">
        <v-toolbar color="white">
          <v-toolbar-title>{{ this.block.resourceBlock.resource.name }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            icon
            @click="openInNewTab"
            class="mr-2"
          >
            <v-icon>mdi-open-in-new</v-icon>
          </v-btn>
          <v-btn
            icon
            @click="showDialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <iframe 
          v-if="showDialog" 
          :src="iframeUrl" 
          frameborder="0" 
          ref="iframe" 
          onmousewheel="" 
          width="100%" 
          height="100%" 
          allow="microphone; camera"
          class="iframe-content"
        ></iframe>
      </v-card>
    </v-dialog>
    <v-menu
      v-model="showMenu"
      :close-on-content-click="false"
      :position-x="menuX"
      :position-y="menuY"
      min-width="320px"
    >
      <v-card>
        <v-card-title>{{ $t('blocks.externalHookButtonBlock.menuTitle') }}</v-card-title>
        <v-card-text>
          <TemplateResourceInput
            v-model="resource"
            :placeholder="$t('blocks.externalHookButtonBlock.inputLabel')"
            :block="block"
            v-bind="$attrs"
            v-on="$listeners"
            ref="resourcePicker"
            :loadItems="loadResources"
          />
        </v-card-text>
      </v-card>
    </v-menu>
  </BaseBlock>
</template>

<script>
import BaseBlock from '@/components/block/BaseBlock.vue'
import { mapGetters } from 'vuex'
import MenuItem from '@/components/MenuItem.vue'
import resourceInputMixin from '@/mixins/resourceInputMixin'
import TemplateResourceInput from '@/components/flow/TemplateResourceInput.vue'


export default {
  mixins: [ resourceInputMixin ],
  props: {
    blockUri: {
      type: String,
      required: true
    },
    editorMode: {
      type: Boolean,
      default: false
    },
    spaceUri: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      showMenu: false,
      showDialog: false,
      menuX: 0,
      menuY: 0,
      loading: false,
      errorMessage: null,
    }
  },
  computed: {
    ...mapGetters(['blockWithUri']),
    resource: {
      get() {
        return this.block.resourceBlock.resource
      },
      set(newVal) {
        this.handleItemSelected(newVal)
      }
    },
    block() {
      return this.blockWithUri(this.blockUri)
    },
    resourceLink() {
      return this.block?._links?.resources?.href
    },
    iframeUrl() {
      const redirectUri = this.block.resourceBlock.resource?.redirectUri
      return redirectUri ? `${window.location.origin}${redirectUri}` : ''
    },
  },
  components: {
    BaseBlock,
    MenuItem,
    TemplateResourceInput
  },
  methods: {
    openMenu(event) {
      this.menuX = event.clientX
      this.menuY = event.clientY
      this.showMenu = true
    },
    openInNewTab() {
      window.open(this.iframeUrl, '_blank')
    },
    async handleItemSelected(newVal) {
      // Implement this method to handle the selected item from the resource picker

      this.loading = true
      try {
        this.block.resourceUri = newVal
        await this.block.updateValue()
      } finally {
        this.loading = false
        this.showMenu = false
      }
    },
    buttonClicked() {
      if (this.iframeUrl) {
        this.showDialog = true
      }
    },
  }
}
</script>

<style scoped>
.resource-empty {
  cursor: pointer;
  background: #F2F1EE;
  color: #75746F;
  border-radius: 12px;
  max-width: 450px;
}

.dialog-card {
  display: flex;
  flex-direction: column;
  height: 90vh;
  overflow: hidden;
}

.iframe-content {
  display: block;
  min-height: 0;
  height: calc(90vh - 64px); /* 64px is the toolbar height */
  border: none;
}
</style>
